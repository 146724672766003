import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThanEqual } from "@fortawesome/free-solid-svg-icons";
import { useRecoilValue } from "recoil";
import { userInfo as userInfoAtom } from "../../atoms";
import { createBrowserHistory } from "history";
import { ErrorLevel } from "../../types";
import { securedApi } from "../../api";

const API_URL: string | undefined = process.env.REACT_APP_API;

const TableContaminantsLevels: React.FC = () => {
  const [allContaminantsNamesList, setAllContaminantsNamesList] = useState<
    string[]
  >([]);
  const [organizationErrorLevels, setOrganizationErrorLevels] = useState<
    ErrorLevel[]
  >([]);
  const [state, setState] = useState<ErrorLevel[]>([]);
  const [updated, setUpdated] = useState(false);

  const history = createBrowserHistory({ window });

  const userInfo = useRecoilValue(userInfoAtom);

  const generateTableList = () => {
    if (allContaminantsNamesList.length > 0 && state.length === 0) {
      const elementsList: ErrorLevel[] = [];

      allContaminantsNamesList.map((el) => {
        elementsList.push({
          contaminant: el,
          errorLevel: "",
          warningLevel: "",
        });
      });
      setState(elementsList);
    }

    if (organizationErrorLevels.length > 0 && state.length !== 0 && !updated) {
      setUpdated(true);

      const elementsList = state;
      for (let i = 0; i < organizationErrorLevels.length; i++) {
        elementsList.forEach((item, index) => {
          if (item.contaminant === organizationErrorLevels[i].contaminant) {
            elementsList[index] = organizationErrorLevels[i];
          }
        });
      }

      setState(elementsList);
    }
  };

  useEffect(() => {
    if (userInfo.currentOrganizationId) {
      const contaminants = [];

      securedApi.get(`${API_URL}/api/errorLevels/contaminants`).then((res) => {
        setAllContaminantsNamesList(res.data);
        contaminants.push(res.data);
      });

      securedApi
        .get(`${API_URL}/api/errorLevels/${userInfo.currentOrganizationId}`)
        .then((res) => {
          setOrganizationErrorLevels(res.data.errors);
        });
    }
  }, [userInfo.currentOrganizationId]);

  if (allContaminantsNamesList.length > 0) {
    generateTableList();
  }

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    state.forEach((el) => {
      if (
        !el.hasOwnProperty("errorId") &&
        el.warningLevel !== "" &&
        el.errorLevel !== ""
      ) {
        securedApi
          .post(
            `${API_URL}/api/errorLevels/${userInfo.currentOrganizationId}`,
            {
              contaminant: el.contaminant,
              warningLevel: el.warningLevel,
              errorLevel: el.errorLevel,
              unit: "mg/kg",
            }
          )
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      } else if (el.warningLevel !== "" && el.errorLevel !== "") {
        securedApi
          .put(`${API_URL}/api/errorLevels/${el.errorId}`, {
            contaminant: el.contaminant,
            warningLevel: el.warningLevel,
            errorLevel: el.errorLevel,
            unit: "mg/kg",
            errorId: "aa56702f-c80d-44d6-a8cb-951675b17038",
          })
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      } else {
        securedApi
          .delete(`${API_URL}/api/errorLevels/${el.errorId}`)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      }
    });

    history.back();
  };

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const newArray = [...state];
    if (e.target.name === "errorLevel") {
      newArray[idx].errorLevel = e.target.value;
    } else if (e.target.name === "warningLevel") {
      newArray[idx].warningLevel = e.target.value;
    }
    setState(newArray);
  };

  return (
    <div className="table-container">
      <Form>
        <Table borderless className="contaminants-levels-table">
          <thead className="table-header">
            <tr>
              <th>Contaminant</th>
              <th>
                Warning{" "}
                <FontAwesomeIcon icon={faGreaterThanEqual} size={"sm"} />
              </th>
              <th>
                Error <FontAwesomeIcon icon={faGreaterThanEqual} size={"sm"} />{" "}
              </th>
              <th>Unit</th>
            </tr>
          </thead>
          <div className="separator"></div>
          <tbody key={"measurement"} id={"measurement"}>
            {state.length > 0
              ? state.map((el, idx) => {
                  return (
                    <tr className="table-measures-row" key={el.contaminant}>
                      <td className="label">{el.contaminant}</td>
                      <td>
                        <input
                          className="input"
                          name="warningLevel"
                          type="number"
                          value={el.warningLevel || ""}
                          onChange={(e) => updateInput(e, idx)}
                        />
                      </td>
                      <td>
                        <input
                          className="input"
                          name="errorLevel"
                          type="number"
                          value={el.errorLevel || ""}
                          onChange={(e) => updateInput(e, idx)}
                        />
                      </td>
                      <td className="unit">mg / kg</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
        <div className="buttons">
          <Button className="btn-modal-save" onClick={submitForm}>
            <span className="btn-content">Save</span>
          </Button>
          <Button className="btn-modal-cancel" onClick={history.back}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TableContaminantsLevels;
