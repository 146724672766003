import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useLayoutEffect } from "react";

const API_URL: string | undefined = process.env.REACT_APP_API;

export const securedApi = axios.create({ baseURL: API_URL });

export function useApiAuthInterceptor() {
  const keycloak = useKeycloak();

  const accessToken = keycloak?.keycloak.token ?? "";
  useLayoutEffect(() => {
    const interceptor = securedApi.interceptors.request.use(
      (config) => {
        config.headers = {};
        config.headers.Authorization = "bearer " + accessToken;
        config.headers["Access-Control-Allow-Origin"] = "*";

        return config;
      },
      (err) => Promise.reject(err)
    );

    return () => securedApi.interceptors.request.eject(interceptor);
  }, [accessToken]);
}
