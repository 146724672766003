import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Organization from "../pages/Organization";
import TopBar from "../components/TopBar/TopBar";
import { useRecoilState } from "recoil";
import { userInfo as userInfoAtom } from "../atoms";
import { useKeycloak } from "@react-keycloak/web";
import ProtectedRoute from "./ProtectedRoute";
import SiteTable from "../components/SiteTable/SiteTable";
import Error from "../pages/Error";
import ContaminantsLevels from "../pages/ContaminantsLevels/ContaminantsLevels";
import { securedApi, useApiAuthInterceptor } from "../api";

const API_URL: string | undefined = process.env.REACT_APP_API;

const AppRouter = () => {
  useApiAuthInterceptor();

  const { keycloak, initialized } = useKeycloak();

  const [, setUserInfo] = useRecoilState(userInfoAtom);

  useEffect(() => {
    if (initialized) {
      securedApi.get(`${API_URL}/api/userinfo`).then((res1) => {
        if (!res1.data.userinfo.isAdmin) {
          securedApi
            .get(
              `${API_URL}/api/organizations/${res1.data.userinfo.organizationId}`
            )
            .then((res2) => {
              setUserInfo({
                isAdmin: res1.data.userinfo.isAdmin,
                isManager: res1.data.userinfo.isManager,
                organizationsId: [],
                organizationsName: [],
                currentOrganizationId: res1.data.userinfo.organizationId,
                currentOrganizationName: res2.data.organization.name,
              });
            });
        } else {
          securedApi.get(`${API_URL}/api/organizations`).then((res2) => {
            setUserInfo({
              isAdmin: res1.data.userinfo.isAdmin,
              isManager: res1.data.userinfo.isManager,
              organizationsId: res1.data.userinfo.organizationId,
              organizationsName: res2.data.organizations,
              currentOrganizationId: res2.data.organizations[0].organizationId,
              currentOrganizationName:
                localStorage.getItem("organization") ||
                res2.data.organizations[0].name,
            });
          });
        }
      });
    }
  }, [keycloak, initialized, setUserInfo]);

  return (
    <>
      <BrowserRouter>
        <TopBar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route
            path="organization/:name"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <Organization></Organization>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="site/:id"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <SiteTable />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="contaminants-levels"
            element={
              <ProtectedRoute auth={keycloak.authenticated}>
                <ContaminantsLevels />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
