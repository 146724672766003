import React, { useCallback, useState } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Sites } from "../../types";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentSite } from "../../atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import SiteCardEditModal from "./SiteCardEditModal";
import { userInfo as userInfoAtom } from "../../atoms";
import SiteCardDeleteModal from "./SiteCardDeleteModal";
import WarningsIcon from "../Icons/WarningsIcon/WarningsIcon";
import TrendIcon from "../Icons/TrendIcon/TrendIcon";
import { displayDate } from "../../utils/displayDate";

interface SiteCardEditModal {
  name: "SiteCardEdit";
}

interface SiteCardDeleteModal {
  name: "SiteCardDelete";
}

type VisibleModal = SiteCardEditModal | SiteCardDeleteModal | undefined;

interface Props {
  sites: Sites;
  setShowToast: (val: boolean) => void;
  setErrorMessage: (val: string) => void;
}

const SiteCard: React.FC<Props> = ({
  sites,
  setShowToast,
  setErrorMessage,
}) => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();

  const [site, setSite] = useState(sites);
  const [active, setActive] = useState(true);

  const [, setSiteInfo] = useRecoilState(currentSite);
  const userInfo = useRecoilValue(userInfoAtom);

  const navigate = useNavigate();

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const handleModalOpen = useCallback((modalType) => {
    setVisibleModal({ name: modalType });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "SiteCardEdit":
          return (
            <SiteCardEditModal
              onClose={handleModalClose}
              site={site}
              setSite={setSite}
              setShowToast={setShowToast}
              setErrorMessage={setErrorMessage}
            />
          );
        case "SiteCardDelete":
          return (
            <SiteCardDeleteModal
              onClose={handleModalClose}
              setActive={setActive}
            />
          );
      }
    }
  }, [handleModalClose, visibleModal]);

  if (active) {
    return (
      <Col className="card-wrapper">
        <Card
          className="custom-card-site border-0"
          onClick={() => {
            setSiteInfo({ siteId: site.siteId, siteName: site.name });
            navigate(`/site/${site.siteId}`);
          }}
        >
          <Card.Body>
            <div className="title-card">
              <Card.Title>{site.name}</Card.Title>

              {(userInfo.isAdmin || userInfo.isManager) && (
                <Dropdown align="end" onClick={(e) => e.stopPropagation()}>
                  <Dropdown.Toggle
                    className="site-card-dropdown-button"
                    variant="success"
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setSiteInfo({
                          siteId: site.siteId,
                          siteName: site.name,
                        });
                        handleModalOpen("SiteCardEdit");
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSiteInfo({
                          siteId: site.siteId,
                          siteName: site.name,
                        });
                        handleModalOpen("SiteCardDelete");
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <Row className="site-data-row">
              <Col xs={4} className="test-count">
                <header className="custom-card-site-header">
                  #&nbsp;Tests
                </header>
                <Card.Body className="custom-card-site-statistics-data">
                  <div className="tests-count">{site.siteStats.testsCount}</div>
                </Card.Body>
                <footer className="custom-card-site-footer">
                  Last 30 days
                </footer>
              </Col>
              <Col xs={4} className="min-score">
                <header className="custom-card-site-header">Last Test</header>
                <Card.Body className="custom-card-site-statistics-data">
                  <WarningsIcon icon={site.lastStatus} />
                </Card.Body>
                <footer className="custom-card-site-footer">
                  {displayDate(site.lastResult)}
                </footer>
              </Col>
              <Col xs={4} className="avg-score">
                <header className="custom-card-site-header">
                  Trend <FontAwesomeIcon icon={faArrowTrendUp} />
                </header>
                <Card.Body className="card-body">
                  <TrendIcon icon={site.trend} />
                </Card.Body>
                <footer className="custom-card-site-footer">
                  Last 30 days
                </footer>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {renderModals()}
      </Col>
    );
  } else {
    return null;
  }
};

export default SiteCard;
