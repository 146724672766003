import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./style.scss";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { createBrowserHistory } from "history";
import { useRecoilValue } from "recoil";
import { userInfo as userInfoAtom } from "../../atoms";

const PreviousPage: React.FC = () => {
  const history = createBrowserHistory({ window });
  const userInfo = useRecoilValue(userInfoAtom);

  return (
    <div className="navigation-previous-page">
      <button onClick={history.back}>
        <FontAwesomeIcon icon={faAngleLeft} size="sm" />{" "}
        {userInfo.currentOrganizationName}
      </button>
    </div>
  );
};
export default PreviousPage;
