import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import SitesList from "../SitesList/SitesList";
import { Sites, Organization, OrganizationParams } from "../../types";
import OrganizationName from "./OrganizationName";
import { useRecoilValue } from "recoil";
import { userInfo as userInfoAtom } from "../../atoms";
import SelectOrganization from "../Navigation/SelectOrganization";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Toast, ToastContainer } from "react-bootstrap";
import ModalAddSite from "../ModalAddSite/ModalAddSite";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { securedApi } from "../../api";

const API_URL: string | undefined = process.env.REACT_APP_API;

interface AddSiteModal {
  name: "AddSite";
}

type VisibleModal = AddSiteModal | undefined;

interface Props {
  organizationName: string;
}

const OrganizationSelect: React.FC<Props> = ({ organizationName }) => {
  const [organizations, setOrganizations] = useState<Organization[] | null>(
    null
  );
  const [selectedOrganization, setSelectedOrganization] =
    useState<Sites | null>(null);

  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();
  const { name } = useParams<OrganizationParams>();

  const userInfo = useRecoilValue(userInfoAtom);

  if (organizationName === undefined) {
    navigate(`/organization/${userInfo.currentOrganizationName}`);
  }

  const handleModalClose = useCallback(() => {
    // TODO temporary solution with timeout for long time waiting for BE to update after POST creation new site method.
    //  Without it we fetch list of sites without new one
    setTimeout(() => {
      setVisibleModal(undefined);
    }, 1000);
  }, []);

  const handleModalOpen = useCallback((modalType) => {
    setVisibleModal({ name: modalType });
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "AddSite":
          return (
            <ModalAddSite
              onClose={handleModalClose}
              setShowToast={setShowToast}
              setErrorMessage={setErrorMessage}
            />
          );
      }
    }
  }, [handleModalClose, visibleModal]);

  useEffect(() => {
    if (userInfo.currentOrganizationId) {
      securedApi
        .get(
          `${API_URL}/api/organizations/${userInfo.currentOrganizationId}/sites`
        )
        .then((res) => {
          setOrganizations(res.data);
          setSelectedOrganization(res.data);
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  }, [userInfo, visibleModal]);

  if (organizations && selectedOrganization) {
    return (
      <div className="page-container">
        <div className="site-cards-page-container">
          <div className="organization-header">
            {!userInfo.isAdmin ? (
              userInfo.currentOrganizationName !== name ? (
                navigate("/*")
              ) : (
                <OrganizationName
                  organizationName={userInfo.currentOrganizationName}
                />
              )
            ) : (
              <SelectOrganization />
            )}
            <Col className="d-flex organization-header-buttons">
              {userInfo.isAdmin || userInfo.isManager ? (
                <>
                  <Button
                    onClick={() => navigate("/contaminants-levels")}
                    className="configure-contaminants-level-button"
                  >
                    <span className="btn-content">
                      <FontAwesomeIcon icon={faGear} />
                      &nbsp;Configure
                    </span>
                  </Button>
                  <Button
                    onClick={() => handleModalOpen("AddSite")}
                    className="add-site-button d-flex"
                  >
                    <span className="btn-content">+ Add</span>
                  </Button>
                </>
              ) : null}
            </Col>
          </div>
          <SitesList
            sites={selectedOrganization}
            setShowToast={setShowToast}
            setErrorMessage={setErrorMessage}
          />
          <ToastContainer className="position-fixed bottom-0 end-0 p-3">
            <Toast
              onClose={() => setShowToast(false)}
              autohide
              show={showToast}
              delay={3000}
              bg={errorMessage ? "danger" : "light"}
            >
              <Toast.Header>
                <strong className="me-auto">Test manager</strong>
              </Toast.Header>
              <Toast.Body>{errorMessage || "Site has been saved."}</Toast.Body>
            </Toast>
          </ToastContainer>
        </div>
        {renderModals()}
      </div>
    );
  } else {
    return <div>Loading sites...</div>;
  }
};

export default OrganizationSelect;
