import React from "react";
import "./style.scss";

interface Props {
  icon: string;
}

const TrendIcon: React.FC<Props> = ({ icon }) => {
  switch (icon) {
    case "U":
      return (
        <div className="trend-grow">
          <i className="bi-arrow-up-right-square"></i>
        </div>
      );

    case "S":
      return (
        <div className="trend-no-change">
          <i className="bi-arrow-right-square"></i>
        </div>
      );

    case "D":
      return (
        <div className="trend-decrease">
          <i className="bi-arrow-down-right-square"></i>
        </div>
      );

    default:
      break;
  }

  return (
    <div className="trend-undefined">
      <span>&ndash;</span>
    </div>
  );
};

export default TrendIcon;
