import React from "react";
import "./style.scss";

interface SiteName {
  name: string;
}

const SiteName: React.FC<SiteName> = ({ name }) => {
  return (
    <div className="site-name">
      <p>{name}</p>
    </div>
  );
};

export default SiteName;
