import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./style.scss";
import { Filter } from "../../types";

import SelectSearch from "react-select-search";
import "react-select-search/style.css";

interface Props {
  filters: Filter;
  setFilters: (val: Filter) => void;
  filterParams: Filter | undefined;
  setOffset: (val: number) => void;
}

const Filters: React.FC<Props> = ({
  filters,
  setFilters,
  filterParams,
  setOffset,
}) => {
  const [users, setUsers] = useState<any>([]);
  const [batches, setBatches] = useState<any>([]);
  const [devices, setDevices] = useState<any>([]);

  useEffect(() => {
    if (filterParams !== undefined) {
      // @ts-ignore
      const usersArray = filterParams.user.map((el) => {
        return {
          name: el,
          value: el,
        };
      });
      usersArray.push({ name: "Not selected", value: "Not selected" });
      setUsers(usersArray);

      // @ts-ignore
      const devicesArray = filterParams.device.map((el) => {
        return {
          name: el,
          value: el,
        };
      });
      devicesArray.push({ name: "Not selected", value: "Not selected" });
      setDevices(devicesArray);

      // @ts-ignore
      const batchesArray = filterParams.batch.map((el) => {
        return {
          name: el,
          value: el,
        };
      });
      batchesArray.push({ name: "Not selected", value: "Not selected" });
      setBatches(batchesArray);
    }
  }, [filterParams]);

  if (users.length !== 0) {
    return (
      <div className="filters">
        <Col xs={2} xxl={1} className="filterLabel">
          Filter&nbsp;by:
        </Col>
        <Row className="filtersRow">
          <Col md={12} lg={4} xl={4}>
            <div className="filterItem">
              <Form.Label className="filterLabel">User</Form.Label>
              {/*@ts-ignore*/}
              <SelectSearch
                options={users}
                search={true}
                onChange={(e) =>
                  // @ts-ignore
                  {
                    // @ts-ignore
                    setFilters({ ...filters, user: e });
                    setOffset(1);
                  }
                }
                placeholder={"Not selected"}
              />
            </div>
          </Col>
          <Col md={12} lg={4} xl={4}>
            <div className="filterItem">
              <Form.Label className="filterLabel-device">
                Device&nbsp;ID
              </Form.Label>
              {/*@ts-ignore*/}
              <SelectSearch
                options={devices}
                search={true}
                onChange={(e) =>
                  // @ts-ignore
                  {
                    // @ts-ignore
                    setFilters({ ...filters, device: e });
                    setOffset(1);
                  }
                }
                placeholder={"Not selected"}
              />
            </div>
          </Col>
          <Col md={12} lg={4} xl={4}>
            <div className="filterItem">
              <Form.Label className="filterLabel-batch">Batch#</Form.Label>
              {/*@ts-ignore*/}
              <SelectSearch
                options={batches}
                search={true}
                onChange={(e) =>
                  // @ts-ignore
                  {
                    // @ts-ignore
                    setFilters({ ...filters, batch: e });
                    setOffset(1);
                  }
                }
                placeholder={"Not selected"}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
};

export default Filters;
