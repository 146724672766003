import React, { useState } from "react";
import "./style.scss";
import { Button, Form, Modal } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { currentSite } from "../../atoms";
import { Sites } from "../../types";
import { securedApi } from "../../api";

const API_URL: string | undefined = process.env.REACT_APP_API;

interface Props {
  onClose: () => void;
  site: Sites;
  setSite: (val: Sites) => void;
  setShowToast: (val: boolean) => void;
  setErrorMessage: (val: string) => void;
}

const SiteCardEditModal: React.FC<Props> = ({
  onClose,
  site,
  setSite,
  setShowToast,
  setErrorMessage,
}) => {
  const siteInfo = useRecoilValue(currentSite);
  const [newSiteName, setNewSiteName] = useState<string>("");

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    securedApi
      .put(`${API_URL}/api/sites/${siteInfo?.siteId}`, {
        name: newSiteName,
        lastStatus: site.lastStatus,
        trend: site.trend,
      })
      .then(() => {
        setSite({ ...site, name: newSiteName });
        setShowToast(true);
        setErrorMessage("");
      })
      .catch((error) => {
        console.error(error);
        setShowToast(true);
        setErrorMessage("Site with the same name already exists.");
      });

    onClose();
  };

  return (
    <Modal show onHide={onClose} className="modal-site">
      <Modal.Header closeButton className="header">
        <Modal.Title>Edit Site</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="mb-4" controlId="Form.ControlInput1">
            <Form.Label>Site Name</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Enter Comment"
              defaultValue={siteInfo.siteName}
              onChange={(e) => setNewSiteName(e.target.value)}
            />
          </Form.Group>
          <Button className="btn-modal-save" type="button" onClick={submitForm}>
            Save
          </Button>
          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer"></Modal.Footer>
    </Modal>
  );
};

export default SiteCardEditModal;
