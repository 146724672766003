import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Form, FormText, ListGroup, Modal } from "react-bootstrap";
import { Sites, Measurement } from "../../types";
import Select from "react-dropdown-select";
import { useRecoilValue } from "recoil";
import { userInfo as userInfoAtom } from "../../atoms";
import WarningsIcon from "../Icons/WarningsIcon/WarningsIcon";
import { securedApi } from "../../api";

const API_URL: string | undefined = process.env.REACT_APP_API;

interface Props {
  onClose: () => void;
  record: Measurement | undefined;
  measurements: Measurement[];
  site: Sites;
  onFadeYellowEffect: (val: number | undefined) => void;
}

interface Tag {
  value: string;
  label: string;
}

const ModalTestDetails: React.FC<Props> = ({
  onClose,
  record,
  measurements,
  site,
  onFadeYellowEffect,
}) => {
  const [comment, setComment] = useState<string | undefined>(record?.comment);
  const [tags, setTags] = useState<string[] | undefined>(record?.tags);
  const [batch, setBatch] = useState<string | undefined>(record?.batch);

  const [tagsList, setTagsList] = useState([]);
  const [inputTags, setInputTags] = useState<Tag[]>([]);

  const userInfo = useRecoilValue(userInfoAtom);

  const loadTags = () => {
    if (tags) {
      const tagsToLoad = tags.map((el) => {
        return { value: el, label: el } as Tag;
      });
      setInputTags(tagsToLoad);
    }
  };

  useEffect(() => {
    loadTags();
    securedApi
      .get(
        `${API_URL}/api/organizations/${userInfo.currentOrganizationId}/tags`
      )
      .then((response) => {
        // @ts-ignore
        let options = response.data.tags.map((el) => {
          let option = { value: el.tag, label: el.tag };
          return option;
        });
        setTagsList(options);
      });

    setTags(record?.tags);
  }, [record]);

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const index = measurements.findIndex(
      (obj) => obj.resultId === record?.resultId
    );

    if (comment === "") {
      measurements[index].comment = "";
    } else if (comment) {
      measurements[index].comment = comment;
    }
    if (tags) {
      measurements[index].tags = tags;
    }
    if (batch === "") {
      measurements[index].batch = "";
    } else if (batch) {
      measurements[index].batch = batch;
    }
    const updatedMeasurements = measurements;
    const updatedSite = { measurements: updatedMeasurements };

    //TODO loading on

    securedApi
      .put(
        `${API_URL}/api/results/${record?.resultId}`,
        updatedSite.measurements[index]
      )
      .then((response) => {
        onFadeYellowEffect(record?.resultId);
        setComment("");
        //TODO loading off
        onClose();
      })
      .catch((error) => {
        console.error(error);
        //TODO loading off
      });
  };

  const convertDate = (el: string | undefined) => {
    return el && new Date(el).toISOString().slice(0, 19).replace("T", " ");
  };

  const handleTagsInput = (value: Tag[]) => {
    const updatedTags = value.map((el) => {
      return el.value;
    });

    setTags(updatedTags);
  };

  if (record) {
    return (
      <Modal
        show
        animation={false}
        onHide={onClose}
        className="modal-test-details"
      >
        <Modal.Header closeButton className="header">
          <Modal.Title>Test Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="body">
          <ListGroup horizontal className="my-2">
            <ListGroup.Item className="item" style={{ width: "40%" }}>
              <FormText>Date and Time</FormText>
              <br />
              {convertDate(record.resultTS)}
            </ListGroup.Item>
            <ListGroup.Item className="item">
              <FormText>User</FormText>
              <br />
              {record.contributor}
            </ListGroup.Item>
          </ListGroup>

          <ListGroup horizontal className="my-2">
            <ListGroup.Item className="item" style={{ width: "40%" }}>
              <FormText>Device</FormText>
              <br />
              {record.device}
            </ListGroup.Item>
            <ListGroup.Item className="item">
              <FormText>Cartridge</FormText>
              <br />
              {record.cartridge}
            </ListGroup.Item>
          </ListGroup>

          <ListGroup>
            <ListGroup.Item className="item">
              <FormText>Result</FormText>
              <br />
              <WarningsIcon icon={record.status} />
            </ListGroup.Item>
          </ListGroup>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="record-form"
          >
            <Form.Group className="mb-3" controlId="Form.ControlInput1">
              <Form.Label>Batch #</Form.Label>
              <Form.Control
                as="textarea"
                onKeyPress={(e) => {
                  e.code === "Enter" && e.preventDefault();
                }}
                rows={1}
                placeholder="Enter Batch"
                defaultValue={record.batch}
                onChange={(e) => setBatch(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Form.ControlInput1">
              <Form.Label>Tags</Form.Label>
              <Select
                multi
                create
                placeholder="Add new tags..."
                options={tagsList}
                color="#4a800b"
                values={inputTags}
                onChange={(value) => handleTagsInput(value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Form.ControlTextarea1">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                onKeyPress={(e) => {
                  e.code === "Enter" && e.preventDefault();
                }}
                rows={4}
                placeholder="Enter Comment"
                defaultValue={record.comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
            <Button
              className="btn-modal-save"
              type="button"
              onClick={submitForm}
            >
              Save
            </Button>
            <Button className="btn-modal-cancel" onClick={onClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer className="footer"></Modal.Footer>
      </Modal>
    );
  } else return null;
};

export default ModalTestDetails;
