import React from "react";
import "./style.scss";

interface IOrganizationName {
  organizationName: string;
}

const OrganizationName: React.FC<IOrganizationName> = ({
  organizationName,
}) => {
  return (
    <div className="organization-name">
      <p>{organizationName}</p>
    </div>
  );
};

export default OrganizationName;
