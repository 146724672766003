import React from "react";
import "./style.scss";

interface Props {
  icon: string;
}

const WarningsIcon: React.FC<Props> = ({ icon }) => {
  switch (icon) {
    case "O":
      return <div className="warning-level-ok">OK</div>;

    case "W":
      return (
        <div className="warning-level-warn">
          <i className="bi-bell"></i> Warn
        </div>
      );

    case "E":
      return (
        <div className="warning-level-bad">
          <i className="bi-bell"></i> Error
        </div>
      );

    default:
      break;
  }

  return (
    <div className="warning-undefined">
      <span>&ndash;</span>
    </div>
  );
};

export default WarningsIcon;
