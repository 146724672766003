import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Form, Modal } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { userInfo as userInfoAtom } from "../../atoms";
import { securedApi } from "../../api";
const API_URL: string | undefined = process.env.REACT_APP_API;

interface Props {
  onClose: () => void;
  setShowToast: (val: boolean) => void;
  setErrorMessage: (val: string) => void;
}

const ModalAddSite: React.FC<Props> = ({
  onClose,
  setShowToast,
  setErrorMessage,
}) => {
  const userInfo = useRecoilValue(userInfoAtom);
  const [newSiteName, setNewSiteName] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<string>("");

  useEffect(() => {
    if (newSiteName.length > 0) {
      setValidationErrors("");
    }
  }, [newSiteName.length]);

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (newSiteName.length > 0) {
      securedApi
        .post(
          `${API_URL}/api/organizations/${userInfo.currentOrganizationId}/sites`,
          { name: newSiteName }
        )
        .then(() => {
          setShowToast(true);
          setErrorMessage("");
        })
        .catch((error) => {
          setShowToast(true);
          setErrorMessage("Site with the same name already exists.");
          console.error(error);
        });
      onClose();
    } else {
      setValidationErrors("Site name is required!");
    }
  };

  return (
    <Modal show onHide={onClose} className="modal-site">
      <Modal.Header closeButton className="header">
        <Modal.Title>Add Site</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <Form onSubmit={submitForm}>
          <Form.Group className="mb-4" controlId="Form.ControlInput1">
            <Form.Label>Site Name</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Enter Site Name"
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  submitForm(e);
                }
              }}
              onChange={(e) => setNewSiteName(e.target.value)}
            />
            {
              <div
                className="text-danger"
                style={{
                  display: validationErrors ? "block" : "none",
                }}
              >
                {validationErrors}
              </div>
            }
          </Form.Group>
          <Button className="btn-modal-save" type="submit" onClick={submitForm}>
            Save
          </Button>
          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer"></Modal.Footer>
    </Modal>
  );
};

export default ModalAddSite;
