import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { userInfo as userInfoAtom } from "../../atoms";
import { useNavigate, useParams } from "react-router-dom";
import { OrganizationParams } from "../../types";

const SelectOrganization: React.FC = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);

  const navigate = useNavigate();

  function truncateName(name: string, n: number) {
    return name.length > n ? name.slice(0, n - 1) + " (...)" : name;
  }

  const { name } = useParams<OrganizationParams>();

  useEffect(() => {
    if (
      name !== undefined &&
      userInfo.organizationsName.some((el) => el.name === name)
    ) {
      localStorage.setItem("organization", name);
      setUserInfo({ ...userInfo, currentOrganizationName: name });
    } else {
      // goes to the error page
      navigate("*");
    }
  }, [name]);

  return (
    <div>
      <Dropdown className="organization-select">
        <Dropdown.Toggle className="organization-dropdown-button">
          {truncateName(userInfo.currentOrganizationName, 60)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {userInfo.organizationsName.map((el, idx) => (
            <Dropdown.Item
              key={idx}
              onClick={() => {
                setUserInfo({
                  ...userInfo,
                  currentOrganizationId:
                    userInfo.organizationsName[idx].organizationId,
                  currentOrganizationName: userInfo.organizationsName[idx].name,
                });
                navigate(
                  `/organization/${userInfo.organizationsName[idx].name}`
                );
              }}
              className="organization-select-item"
            >
              {truncateName(el.name, 35)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SelectOrganization;
