import React from "react";
import PreviousPage from "./PreviousPage";

interface INavigation {
  enablePreviousPage: boolean;
}

const Navigation: React.FC<INavigation> = ({ enablePreviousPage }) => {
  return (
    <div className="navigation">{enablePreviousPage && <PreviousPage />}</div>
  );
};

export default Navigation;
