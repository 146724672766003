import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import "./style.scss";
import { faEye, faEyeSlash, faPencil } from "@fortawesome/free-solid-svg-icons";
import Navigation from "../Navigation/Navigation";
import { Filter, Measurement } from "../../types";
import TableRecordDescription from "./TableRecordDescription";
import ModalTestDetails from "../ModalTestDetails/ModalTestDetails";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentSite as currentSiteAtom } from "../../atoms";
import CustomPagination from "../CustomPagination/CustomPagination";
import SiteName from "./SiteName";
import WarningsIcon from "../Icons/WarningsIcon/WarningsIcon";
import { securedApi } from "../../api";

const API_URL: string | undefined = process.env.REACT_APP_API;
const classNames = require("classnames");

const MAX_PAGINATION: number = 50;

interface TestDetailsModal {
  name: "TestDetails";
  record: Measurement;
}

type VisibleModal = TestDetailsModal | undefined;

const SiteTable: React.FC = () => {
  const [site, setSite] = useState<any>(undefined);
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showModalTestDetails, setShowModalTestDetails] =
    useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<VisibleModal>();
  const [rowID, setRowID] = useState<number | undefined>(undefined);
  const { id } = useParams();
  const currentSite = useRecoilValue(currentSiteAtom);

  const [offset, setOffset] = useState<number>(1);
  const [numberOfResults, setNumberOfResults] = useState<number | undefined>(
    undefined
  );
  const [filters, setFilters] = useState({
    batch: "Not selected",
    device: "Not selected",
    user: "Not selected",
  });
  const [filterParams, setFilterParams] = useState<Filter>();

  const [expandedRows, setExpandedRows] = useState<boolean[]>(
    Array.from(Array(MAX_PAGINATION)).fill(false)
  );

  const filtering = `${
    filters.batch !== "Not selected"
      ? `&field=batch&value=${filters.batch}`
      : ""
  }${
    filters.device !== "Not selected"
      ? `&field=device&value=${filters.device}`
      : ""
  }${
    filters.user !== "Not selected"
      ? `&field=contributor&value=${filters.user}`
      : ""
  }`;

  // @ts-ignore
  function filtersParameters(data) {
    if (data) {
      const parameters = {
        user: data.contributors,
        device: data.devices,
        batch: data.batches,
      };

      // @ts-ignore
      setFilterParams(parameters);
    }
  }

  useEffect(() => {
    securedApi.get(`${API_URL}/api/sites/${id}/results/count`).then((res) => {
      setNumberOfResults(res.data.count);
    });

    securedApi
      .get(
        `${API_URL}/api/sites/${id}/results?offset=${
          offset - 1
        }&limit=${MAX_PAGINATION}${filtering}`
      )
      .then((res) => {
        setMeasurements(res.data.results);
        setSite(res.data);
      })
      .catch((error) => {
        console.log(error.response);
      });

    securedApi
      .get(`${API_URL}/api/sites/${id}/results/filters`)
      .then((res) => {
        filtersParameters(res.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [offset, filtering]);

  if (measurements) {
    measurements.forEach((el) => Date.parse(el.resultTS));
    measurements.sort(function (a, b) {
      return +new Date(b.resultTS) - +new Date(a.resultTS);
    });
  }

  function handleFadeYellowEffect(rowID?: number) {
    setRowID(rowID);
    setTimeout(function () {
      setRowID(undefined);
    }, 1000);
  }

  function handleEditButton(record: Measurement) {
    setVisibleModal({ name: "TestDetails", record });
  }

  const convertDate = (el: string) => {
    return new Date(el).toISOString().slice(0, 19).replace("T", " ");
  };

  function showHideAll() {
    setShowDetails(!showDetails);
    if (showDetails) {
      setExpandedRows([...expandedRows.fill(false)]);
    } else {
      setExpandedRows([...expandedRows.fill(true)]);
    }
  }

  function showDescriptions() {
    if (expandedRows.some((x) => x)) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }

  function updateRowExpand(idx: number) {
    let newList = expandedRows;
    newList[idx] = !newList[idx];
    setExpandedRows([...newList]);
    showDescriptions();
  }

  const handleModalClose = useCallback(() => {
    setVisibleModal(undefined);
  }, []);

  const renderModals = useCallback(() => {
    if (visibleModal !== undefined) {
      switch (visibleModal.name) {
        case "TestDetails":
          return (
            <ModalTestDetails
              onClose={handleModalClose}
              record={visibleModal.record}
              measurements={measurements}
              site={site}
              onFadeYellowEffect={handleFadeYellowEffect}
            />
          );
      }
    }
  }, [
    handleModalClose,
    measurements,
    showModalTestDetails,
    site,
    visibleModal,
  ]);

  return (
    <>
      <div className="page-container">
        <Navigation enablePreviousPage={true} />
        <SiteName name={currentSite.siteName} />
        <CustomPagination
          pagination={MAX_PAGINATION}
          offset={offset}
          numberOfResults={numberOfResults}
          setOffset={setOffset}
          filterEnabled={true}
          filters={filters}
          setFilters={setFilters}
          filterParams={filterParams}
        />
        <Table className="site-table">
          <thead>
            <tr>
              <th className="date-and-time">Date and time</th>
              <th className="user">User</th>
              <th className="device">Device</th>
              <th className="cartridge">Cartridge</th>
              <th className="batch">Batch #</th>
              <th className="result">Results</th>
              <th className="show-details-column">
                <Button
                  onClick={() => showHideAll()}
                  variant="outline-success"
                  className="btn-show-details"
                >
                  {!showDetails ? (
                    <>
                      <FontAwesomeIcon icon={faEye} /> Show Details
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faEyeSlash} /> Hide Details
                    </>
                  )}
                </Button>
              </th>
            </tr>
          </thead>
          {measurements.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <div className="no-results">There is no results to show.</div>
                  <Button
                    className="btn-refresh"
                    onClick={() => window.location.reload()}
                  >
                    Click to reload!
                  </Button>
                </td>
              </tr>
            </tbody>
          ) : null}
          {measurements.map((el, idx) => (
            <tbody
              key={"measurement" + idx}
              id={"measurement-" + el.resultId}
              className={classNames("table-body", {
                "fade-yellow-effect-on": rowID === el.resultId,
                "fade-yellow-effect-off": rowID !== el.resultId,
              })}
              onClick={() => {
                updateRowExpand(idx);
              }}
            >
              <tr className="table-measures-row" key={idx}>
                <td>{convertDate(el.resultTS)}</td>
                <td>{el.contributor}</td>
                <td>{el.device}</td>
                <td>{el.cartridge}</td>
                <td className="value">
                  {el.batch === "" ? <span>&ndash;</span> : el.batch}
                </td>
                <td>
                  <WarningsIcon icon={el.status} />
                </td>
                <td rowSpan={2} className="edit-button-column">
                  <Button
                    onClick={(e) => {
                      handleEditButton(el);
                      e.stopPropagation();
                    }}
                    variant="outline-success"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                </td>
              </tr>
              <tr>
                <TableRecordDescription
                  record={el}
                  visible={expandedRows[idx]}
                  spanColumns={6}
                />
              </tr>
            </tbody>
          ))}
        </Table>
        <CustomPagination
          pagination={MAX_PAGINATION}
          offset={offset}
          numberOfResults={numberOfResults}
          setOffset={setOffset}
          filterEnabled={false}
          filters={filters}
          setFilters={setFilters}
          filterParams={filterParams}
        />
      </div>
      {renderModals()}
    </>
  );
};

export default SiteTable;
