import { atom } from "recoil";

interface userInfoInterface {
  isAdmin: boolean;
  isManager: boolean;
  organizationsId: string[];
  organizationsName: any[];
  currentOrganizationId: string;
  currentOrganizationName: string;
}

export const userInfo = atom({
  key: "userInfo",
  default: {
    isAdmin: false,
    isManager: false,
    organizationsId: [],
    organizationsName: [],
    currentOrganizationId: "",
    currentOrganizationName: "",
  } as userInfoInterface,
});

export const currentSite = atom({
  key: "currentSite",
  default: {
    siteId: "",
    siteName: "",
  },
});
