import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./style.scss";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

const TopBar: React.FC = () => {
  const [userName, setUserName] = useState<string>("");

  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserInfo = () => {
      if (keycloak?.authenticated) {
        keycloak.loadUserInfo().then((response) => {
          // @ts-ignore
          setUserName(response.name || response.preferred_username);
        });
      }
    };

    getUserInfo();
  });

  const handleLogo = () => {
    if (keycloak?.authenticated) {
      return navigate("home");
    }
  };

  return (
    <Navbar className="navbar-style" variant="dark">
      <Navbar.Brand className="nav-style ml-4">
        <Nav.Item>
          <Nav.Link
            onClick={() => handleLogo()}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="custom-logo">
              <img
                src={require("../../common/images/logo.png")}
                width="50"
                height="50"
              />
              <div className="logo-name">TEST MANAGER</div>
            </div>
          </Nav.Link>
        </Nav.Item>
      </Navbar.Brand>
      <div className="username-login-container">
        <Navbar.Text className="justify-content-end">
          <Nav>
            {keycloak.authenticated ? (
              <div className="login-logout-container">
                <Nav.Item className="username">{userName}</Nav.Item>
                <Nav.Link
                  className="logout-navbar"
                  onClick={() => {
                    navigate("home");
                    keycloak.logout();
                  }}
                >
                  Log out
                </Nav.Link>
              </div>
            ) : (
              <div className="login-logout-container">
                <Nav.Link onClick={() => keycloak.login()}>Log In</Nav.Link>
              </div>
            )}
          </Nav>
        </Navbar.Text>
      </div>
    </Navbar>
  );
};

export default TopBar;
