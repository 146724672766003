import React from "react";
import { Row } from "react-bootstrap";
import "./style.scss";
import SiteCard from "../SiteCard/SiteCard";
import { Sites } from "../../types";

interface SiteList {
  sites: Sites;
  setShowToast: (val: boolean) => void;
  setErrorMessage: (val: string) => void;
}

const SitesList: React.FC<SiteList> = ({
  sites,
  setShowToast,
  setErrorMessage,
}) => {
  if (sites) {
    return (
      <div className="sites-container">
        <Row xs={1} md={5} className="g-5">
          {/*@ts-ignore*/}
          {sites.sites.map((el: Sites) => (
            <SiteCard
              sites={el}
              key={el.siteId}
              setShowToast={setShowToast}
              setErrorMessage={setErrorMessage}
            />
          ))}
        </Row>
      </div>
    );
  } else {
    return null;
  }
};

export default SitesList;
