import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Collapse } from "react-bootstrap";
import { Measurement } from "../../types";

const classNames = require("classnames");

interface Props {
  record: Measurement;
  visible: boolean;
  spanColumns: number;
}

const TableRecordDescription: React.FC<Props> = ({
  record,
  visible,
  spanColumns,
}) => {
  const descriptionClass = classNames("table-record-description", { visible });

  const parseComment = (comment: string) => {
    const regex = /\[(https?:\/\/[^\s]+)\s*\|\s*([^\]]+)\]/g;
    return comment.replace(
      regex,
      '<a href="$1" target="_blank" onClick="event.stopPropagation()">$2</a>'
    );
  };

  return (
    <td colSpan={spanColumns} className={descriptionClass}>
      <Collapse in={visible}>
        <div>
          <span className="measurement-description">
            <FontAwesomeIcon
              icon={["far", "comment"]}
              className="comment-icon"
            />
            {record.comment === "" ? (
              <div className="description-text no-data">
                <span>&ndash;</span>
              </div>
            ) : (
              <div
                className="description-text"
                dangerouslySetInnerHTML={{
                  __html: parseComment(record.comment),
                }}
              />
            )}
          </span>
          <br />
          <span className="measurement-description">
            {record.tags.length === 0 ? (
              <span className="measurement-description">
                <FontAwesomeIcon
                  icon={["fas", "tag"]}
                  className="tag-icon"
                  size={"lg"}
                />
                <span>&ndash;</span>
              </span>
            ) : (
              record.tags.map((tag) => (
                <span key={tag} className="measurement-description">
                  <FontAwesomeIcon
                    icon={["fas", "tag"]}
                    className="tag-icon"
                    size={"lg"}
                  />
                  <div className="measurement-tag">{tag}</div>
                </span>
              ))
            )}
          </span>
        </div>
      </Collapse>
    </td>
  );
};

export default TableRecordDescription;
