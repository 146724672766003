import React from "react";
import "./App.scss";

import Keycloak from "keycloak-js";
import keycloakConf from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import AppRouter from "./routes/AppRouter";
// @ts-ignore
const keycloak = new Keycloak(keycloakConf);

const App: React.FC = () => {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <div className="App">
        <AppRouter />
      </div>
    </ReactKeycloakProvider>
  );
};

export default App;
