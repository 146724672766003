import React from "react";
import { Button, Image } from "react-bootstrap";
import "../App.scss";
import { Navigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useRecoilValue } from "recoil";

import { userInfo as userInfoAtom } from "../atoms";

const Home: React.FC = () => {
  const { keycloak } = useKeycloak();
  const userInfo = useRecoilValue(userInfoAtom);

  if (keycloak.authenticated && userInfo.currentOrganizationName) {
    return (
      <Navigate to={`/organization/${userInfo.currentOrganizationName}`} />
    );
  }

  return (
    <div className="welcome-page">
      <div className="logo">
        <Image
          src={require("../common/images/welcome_screen_background.png")}
        />
      </div>
      <p>Test Manager</p>
      <div className="button">
        {" "}
        <Button onClick={() => keycloak.login()}>Log In</Button>
      </div>
    </div>
  );
};

export default Home;
