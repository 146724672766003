import React from "react";
import { useRecoilValue } from "recoil";
import { userInfo as userInfoAtom } from "../../atoms";
import OrganizationName from "../../components/OrganizationSelect/OrganizationName";
import "../../App.scss";
import "./style.scss";
import TableContaminantsLevels from "../../components/TableContaminantsLevels/TableContaminantsLevels";

const ContaminantsLevels: React.FC = () => {
  const userInfo = useRecoilValue(userInfoAtom);

  return (
    <div className="page-container">
      <div className="organization-header">
        <OrganizationName
          organizationName={userInfo.currentOrganizationName}
        ></OrganizationName>
      </div>
      <div className="contaminants-levels">
        <p className="header">Contaminants Level</p>
        <TableContaminantsLevels />
      </div>
    </div>
  );
};

export default ContaminantsLevels;
