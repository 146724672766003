import React from "react";
import { Button, Image } from "react-bootstrap";
import "../App.scss";
import { useNavigate } from "react-router-dom";

const Error: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="logo">
        <Image src={require("../common/images/error_screen_background.png")} />
      </div>
      <h1>Oops! Page not found...</h1>
      <h3>But keep calm! You can always go back to:</h3>
      <div className="button">
        {" "}
        <Button onClick={() => navigate("/Home")}>Home Page</Button>
      </div>
    </div>
  );
};

export default Error;
