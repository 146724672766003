import React from "react";
import "./style.scss";
import { Button, Form, Modal } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { currentSite } from "../../atoms";
import { securedApi } from "../../api";

const API_URL: string | undefined = process.env.REACT_APP_API;

interface Props {
  onClose: () => void;
  setActive: (val: boolean) => void;
}

const SiteCardEditModal: React.FC<Props> = ({ onClose, setActive }) => {
  const siteInfo = useRecoilValue(currentSite);

  const submitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    securedApi
      .delete(`${API_URL}/api/sites/${siteInfo?.siteId}`)
      .then(() => {
        setActive(false);
      })
      .catch((error) => {
        console.error(error);
      });

    onClose();
  };

  return (
    <Modal show onHide={onClose} className="modal-site">
      <Modal.Header closeButton className="header">
        <Modal.Title>Delete Site</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="mb-5" controlId="Form.ControlInput1">
            <h5>Are you sure you want to Delete this Site?</h5>
          </Form.Group>
          <Button
            className="btn-modal-delete"
            type="button"
            onClick={submitForm}
          >
            Delete
          </Button>
          <Button className="btn-modal-cancel" onClick={onClose}>
            Cancel
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer"></Modal.Footer>
    </Modal>
  );
};

export default SiteCardEditModal;
