import React, { useEffect, useState } from "react";
import "./style.scss";
import { Col, Pagination, Row } from "react-bootstrap";
import Filters from "../Filters/Filters";
import { Filter } from "../../types";

interface Props {
  pagination: number;
  offset: number;
  numberOfResults: number | undefined;
  setOffset: (val: number) => void;
  filterEnabled: boolean;
  filters: any;
  setFilters: (val: any) => void;
  filterParams: Filter | undefined
}

/**
 *
 * @param pagination - number of results per page
 * @param offset - current page number
 * @param numberOfResults - number of total results for site
 * @constructor
 */
const CustomPagination: React.FC<Props> = ({
  pagination,
  offset,
  numberOfResults,
  setOffset,
  filterEnabled,
  filters,
  setFilters,
    filterParams
}) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const calculatePages = (
    pagination: number,
    numberOfResults: number | undefined
  ) => {
    return numberOfResults ? Math.ceil(numberOfResults / pagination) : 0;
  };

  useEffect(() => {
    setTotalPages(calculatePages(pagination, numberOfResults));
  }, [pagination, numberOfResults, currentPage]);

  const pageClicked = (el: number) => {
    if (el >= 1 && el <= totalPages) {
      setOffset(el);
    }
  };

  if (totalPages) {
    return (
      <div className="main">
        <Row md={1} lg={1} xl={2} xxl={2}>
          <Col lg={12} xl={10} xxl={8}>
            {filterEnabled && (
              <Filters setOffset={setOffset} filters={filters} setFilters={setFilters} filterParams={filterParams} />
            )}
          </Col>
          <Col lg={12} xl={12} xxl={4}>
            <Pagination className="pagination">
              <Pagination.Prev onClick={() => pageClicked(offset - 1)} />
              {offset > 3 ? (
                <Pagination.Item onClick={() => pageClicked(1)}>
                  {1}
                </Pagination.Item>
              ) : null}
              {offset > 4 ? (
                <Pagination.Ellipsis
                  onClick={() => {
                    offset < 10 ? pageClicked(1) : pageClicked(offset - 10);
                  }}
                />
              ) : null}

              {offset > 2 ? (
                <Pagination.Item onClick={() => pageClicked(offset - 2)}>
                  {offset - 2}
                </Pagination.Item>
              ) : null}
              {offset > 1 ? (
                <Pagination.Item onClick={() => pageClicked(offset - 1)}>
                  {offset - 1}
                </Pagination.Item>
              ) : null}
              <Pagination.Item active>{offset}</Pagination.Item>
              {offset < totalPages ? (
                <Pagination.Item onClick={() => pageClicked(offset + 1)}>
                  {offset + 1}
                </Pagination.Item>
              ) : null}
              {offset < totalPages - 1 ? (
                <Pagination.Item onClick={() => pageClicked(offset + 2)}>
                  {offset + 2}
                </Pagination.Item>
              ) : null}

              {offset < totalPages - 3 ? (
                <Pagination.Ellipsis
                  onClick={() => {
                    offset > totalPages - 10
                      ? pageClicked(totalPages)
                      : pageClicked(offset + 10);
                  }}
                />
              ) : null}
              {offset <= totalPages - 3 ? (
                <Pagination.Item onClick={() => pageClicked(totalPages)}>
                  {totalPages}
                </Pagination.Item>
              ) : null}
              <Pagination.Next onClick={() => pageClicked(offset + 1)} />
            </Pagination>
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
};

export default CustomPagination;
