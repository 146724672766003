import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfo as userInfoAtom } from "../atoms";

interface Props {
  auth: boolean | undefined;
  children: any;
}

function ProtectedRoute({ auth, children }: Props) {
  const userInfo = useRecoilValue(userInfoAtom);

  let organizationName = userInfo.currentOrganizationName;

  if (auth !== undefined) {
    return auth ? (
      { ...children, props: { name: organizationName } }
    ) : (
      <Navigate to="/home" />
    );
  } else return null;
}

export default ProtectedRoute;
